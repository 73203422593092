import {
    CAMPAIGN_STATUS,
    DEFAULT_LODGEMENT,
    NETWORK,
    VOLUME_SLICES,
} from "../../const/quote";
import { printCost } from "./calc";

export const hasCampaign = (quote) => {
    if (!quote) return false;
    return (
        quote.quoteMeta.campaignId !== undefined &&
        quote.quoteMeta.campaignId !== null &&
        quote.quoteMeta.campaignId !== ""
    );
};

export const isCampaignCreated = (quotes) => {
    return quotes.some((q) => hasCampaign(q));
};

export const isCampaignLocked = (campaignStatus) => {
    return ![CAMPAIGN_STATUS.PENDING, CAMPAIGN_STATUS.CONFIRMED].includes(
        campaignStatus
    );
};

export const getCampaignIds = (quotes) => {
    return Array.from(
        new Set(
            quotes.flatMap((quote) => {
                if (hasCampaign(quote)) {
                    return quote.quoteMeta.campaignId;
                }
                return [];
            })
        )
    );
};

/**
 * Returns exactly one campaign ID for the given quotes.
 * If there are no matching criteria (e.g. no campaign or more than one), returns null.
 */
export const getCampaignId = (quotes) => {
    const campaignIds = getCampaignIds(quotes);

    if (campaignIds.length !== 1) {
        return null;
    }
    return campaignIds[0];
};

export const createCampaignPayload = (
    campaignName,
    customerCmsCode,
    coordinator
) => {
    return {
        name: campaignName.trim(),
        status: "NEW",
        customer_cms_number: customerCmsCode,
        customer_coordinator: coordinator,
    };
};

export const createCampaignQuotePayload = (
    quote,
    distributionDate,
    jobName
) => {
    return {
        type: "DISTRIBUTION",
        id: quote.id,
        description: jobName?.trim() ?? "",
        start_date: distributionDate,
        meta: {
            weight:
                quote.quoteDetails.customWeightBracket ||
                quote.quoteDetails.weight,
            mailer_type: quote.quoteDetails.mailerType,
            has_freight: quote.quoteDetails.hasFreight ?? true,
            has_min_fee: true,
            has_print: quote.quoteDetails.printingLine,
            enclosed: quote.quoteDetails.enclosed,
            oversized: quote.quoteDetails.oversized,
            targeted:
                quote.quoteDetails.targeted ?? false ? "complex" : "simple",
            extra_copies: quote.quoteDetails.extraCopies,
        },
        rounds: getRoundsData(quote),
        lodgement: quote.quoteDetails.lodgement ?? DEFAULT_LODGEMENT,
        billing_lines: {
            print: printCost(quote.quoteDetails),
        },
    };
};

export const createStandaloneJobPayload = (
    quote,
    distributionDate,
    jobName,
    customerCmsCode,
    coordinator
) => {
    return {
        customer_cms_number: customerCmsCode,
        customer_coordinator: coordinator,
        ...createCampaignQuotePayload(quote, distributionDate, jobName),
    };
};

const getRoundsData = (quote) => {
    const selectedRounds = [];

    quote.territories.forEach((territory) => {
        Object.keys(territory.rounds).forEach((roundId) => {
            const round = territory.rounds[roundId];
            const network = round.layer;
            const countersSelected = territory.networkSelections.includes(
                NETWORK.COUNTERS
            );

            if (
                round.selected &&
                (territory.networkSelections.includes(network) ||
                    (NETWORK.POBOXES === network && countersSelected))
            ) {
                const volumesData = [];

                territory.volumeSelections[network].forEach((channel) => {
                    // If POBOXES network has selected FARMER or RESIDENTIAL channels,
                    // the payload should contain COUNTERS with respective channel
                    if (
                        NETWORK.POBOXES === network &&
                        countersSelected &&
                        [
                            VOLUME_SLICES.FARMER,
                            VOLUME_SLICES.RESIDENTIAL,
                        ].includes(channel)
                    ) {
                        volumesData.push({
                            network: NETWORK.COUNTERS,
                            channel,
                        });
                    }

                    volumesData.push({ network, channel });
                });

                selectedRounds.push({
                    walk_id: Number(roundId),
                    volumes: volumesData,
                });
            }
        });
    });

    return selectedRounds;
};
